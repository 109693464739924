import styled from 'styled-components'
import { Editable } from '../Editable';

export const Subtitulo = styled.div`
  color: #BFB3AF;
  margin-bottom: 15px;
`;

export const Content = styled.div`
`;

export const Block = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
`;

export const LabelOlho = styled.h3`
  color: #BFB3AF;
`;

export const Label = styled.div`
  color: #BFB3AF;
  margin-bottom: 5px;
`;

export const MiniLabel = styled.span`
  color: #BFB3AF;
`;

export const Nome = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #000;
`;

export const Linha = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  justify-content: space-between;
`;

export const BotoesLinha = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 10px;
  justify-content: space-between;
`;

export const Botao = styled.button`
  background: transparent;
  border: 0;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
  color: #811EF9;
  max-width: 100%;
  & > img {
    max-width: 100%;
  }
`;

export const BotaoRecomendacoes = styled.button`
  background: transparent;
  border: 0;
  align-items: center;
  justify-content: center;
  display: flex;
  color: #811EF9;
  & > img {
    margin-right: 8px;
  }
`;

export const Campo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
`;

export const TriplaOpcao = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 30px;
  overflow: hidden;

  & :first-child {
    border-radius: 10px 0 0 10px;
    border-right: none;
  }
  & :last-child {
    border-radius: 0 10px 10px 0;
    border-left: none;
  }
`;

export const Opcao = styled.div`
  border: 2px solid ${(props) => props.selected ? '#35155C' : '#E6D4E6'};
  background: ${(props) => props.selected ? '#35155C' : '#FFFFFF'};
  color: ${(props) => props.selected ? '#FFFFFF' : '#000000'};

  font-size: 14px;
  font-weight: 500;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const Minitexto = styled.span`
  font-size: 10px;
  font-weight: 400;
`;

export const MiniCampo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
`;

export const Valor = styled(Editable)`
  width: 100%;
`;

export const LabelDadosClinicos = styled.div`
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: -0.02em;
  text-align: left;

  color: #811EF9;

  margin-top: 10px;
`;

export const PreAnamnese = styled.div`
  padding-left: 10px;
`;

export const Recomendacoes = styled.div`
`;

export const RecomendacoesLista = styled.div`
  border: 2px solid #BFB3AF;
  padding: 11px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const RecomendacaoLinha = styled.div`
  display: flex;
  justify-content: space-between;

  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-align: left;  
`;

export const Marca = styled.span`
  color: #BFB3AF;
`;

export const LoadingEnviar = styled.div`
  margin-top: 10px;
  width: 352px;
  height: 44px;
  border-radius: 50px;
  background: #811EF9;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LenteContainer = styled.div`
  margin: 15px 0;
`;

export const LenteLinha = styled.div`
  display: flex;
  margin-top: 15px;
`;

export const LenteTextarea = styled.textarea`
  padding: 8px;
  border-radius: 8px;
  border: 2px solid #BFB3AF;
  width: 100%;
  font-family: Inter;
  height: 56px;
`;
