import { useEffect, useRef, useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import {
  Container,
  SelectContainer,
  InputContainer,
  Input,
  OptionsContainer,
  Options,
  Option,
  OptionSubLabel,
  IconDown,
  Descricao
} from './styles';

interface OptionsProps {
  value: string;
  label: string;
  sublabel?: string;
  descricao?: string;
}
interface SelectProps {
  options: OptionsProps[];
  onChange: (value: any) => void;
  autoFocus: boolean;
  placeholder: string;
  mode?: string;
}

export function Select({ options, onChange, placeholder, mode = ""}: SelectProps) {
  const [optionsFiltered, setOptionsFiltered] = useState<OptionsProps[]>([]);
  const [showOptions, setShowOptions] = useState(false);
  const [descricao, setDescricao] = useState('');
  const boxRef = useRef<HTMLDivElement>();

  useEffect(() => {
    setOptionsFiltered(options);
  }, [options]);

  useEffect(() => {
    if(showOptions) {
      boxRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',        
      });
    }
  }, [showOptions]);

  const handleSearchInputChange = (e) => {
    const text = e.target.value;
    const filtered = text === "" ? options : options.filter((option) =>
      option.label.toLowerCase().includes(text.toLowerCase()) ||
      option.sublabel?.toLowerCase().includes(text.toLowerCase()) ||
      option.value.toLowerCase().includes(text.toLowerCase()) ||
      option.descricao?.toLowerCase().includes(text.toLowerCase()) 
    );
    setOptionsFiltered(filtered);
    setShowOptions(true);    
  };  

  function handleBlurContent(e) {
    if (
      e.relatedTarget !== e.currentTarget &&
      !e.currentTarget.contains(e.relatedTarget)
    ) {
      setShowOptions(false);
    }
  }

  function handleOptionHover(option) {
    if(option.descricao) {
      setDescricao(option.descricao);
    }
  }

  function handleOptionBlur() {
    setDescricao('');
  }

  return (
  <Container onBlur={handleBlurContent}>
    <SelectContainer onClick={() => setShowOptions(!showOptions)}>
      <InputContainer>
        <Input type="text" className="ui_labels" onChange={handleSearchInputChange} placeholder={placeholder} autoFocus mode={mode} />
      </InputContainer>
      <IconDown><FaChevronDown /></IconDown>
    </SelectContainer>
    {showOptions &&
    <OptionsContainer ref={boxRef}>
      <Options>
        {optionsFiltered.map((option) => (
          <Option className="ui_labels" onClick={() => onChange(option)} key={option.value} onMouseOver={() => handleOptionHover(option)} onMouseOut={() => handleOptionBlur()}>
            {option.label}
            {option.sublabel && <OptionSubLabel className="ui_mini_labels">{option.sublabel}</OptionSubLabel>}
          </Option>
        ))}
      </Options>
      { descricao &&
        <Descricao className="ui_labels">{descricao}</Descricao>
      }
    </OptionsContainer>
    }
  </Container>
  );
}