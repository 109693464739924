import React, { useEffect, useRef, useState } from "react";
import { toast } from 'react-toastify';

import { useConsulta } from '@hooks/useConsulta';
import { Loading } from '../../components/Loading';

import ImgDitar from '../../assets/new-header/falar_enabled.svg';
import ImgDitarDisable from '../../assets/new-header/falar_disabled.svg';
import ImgParar from '../../assets/new-header/botao_parar.svg';

import { Container, Image, DefaultButton, Button } from './styles'; 
import { isChrome, isIOS, isSafari } from "react-device-detect";

import MicRecorder from 'mic-recorder-to-mp3';
const Mp3Recorder = new MicRecorder({ bitRate: 128 });

export function BotaoDitarSafari({ disabled, loading, changeLoading }) {
  const [isRecording, setIsRecording] = useState(false);
  const [ enviandoAudio, setEnviandoAudio ] = useState(false);

  const { 
    enviarAudio, 
  } = useConsulta();

  const startRecording = async () => {
    changeLoading(true);
    Mp3Recorder.start().then(() => {
      setIsRecording(true);
    }).catch((e) => {
      console.error(e);
    });
  }

  const stopRecording = () => {
    Mp3Recorder.stop().getMp3().then(([buffer, blob]) => {
      var reader = new window.FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = async function () {
         var base64data = reader.result;
         setEnviandoAudio(true);
         await enviarAudio(base64data, 'audio/mp3');
         setEnviandoAudio(false);
         changeLoading(false);
      }
      setIsRecording(false);
    }).catch((e) => {
      alert('We could not retrieve your message');
      console.log(e);
    });
  };

  if(disabled) {
    return (<Image src={ImgDitarDisable} alt="ditar" disabled={true} />)
  }
  
  if(isRecording) {
    return (
    <Button onClick={stopRecording}>
      <Image src={ImgParar} />
    </Button>
    );
  }

  if(enviandoAudio) {
    return (
    <DefaultButton>
      <Loading />
    </DefaultButton>
    );
  }

  return (
    <Container onClick={startRecording}>
      <Image src={ImgDitar} alt="ditar" />
    </Container>
  );
}