import { useState, useEffect } from 'react';

import { RefracaoDTO } from '@dtos/ConsultaDTO';
import { Editable } from '@components/Editable';
import { EditableEixo } from '@components/EditableEixo';
import { onKeyDownAllowOnlyPositiveNumbers, onKeyDownAllowNumeric, onKeyDownAllowNegative } from '@utils/format';
import { useConsulta } from '@hooks/useConsulta';
import { getRefracaoOlhoDireito, getRefracaoOlhoEsquerdo} from '@utils/consultaHelper';
import { 
  Linha,
  Campo,
  LabelOlho,
  Label,
  MiniCampo
} from './styles';

interface RefracaoProps {
  refracao: RefracaoDTO; 
  handleChangeRefracao?: any;
  error?: any; 
}

export function Refracao() {
  const [olhoDireitoEsferico, setOlhoDireitoEsferico] = useState('');
  const [olhoDireitoCilindrico, setOlhoDireitoCilindrico] = useState('');
  const [olhoDireitoAdicao, setOlhoDireitoAdicao] = useState('');

  const [olhoEsquerdoEsferico, setOlhoEsquerdoEsferico] = useState('');
  const [olhoEsquerdoCilindrico, setOlhoEsquerdoCilindrico] = useState('');
  const [olhoEsquerdoAdicao, setOlhoEsquerdoAdicao] = useState('');

  const { 
    receita, 
    alteraReceita, 
    errorReceita,
    setErrorReceita,
    validarReceita
  } = useConsulta();  

  useEffect(() => {
    setOlhoDireitoEsferico(receita.refracao?.OD?.esf || '');
  }, [receita.refracao?.OD?.esf]);

  useEffect(() => {
    setOlhoDireitoCilindrico(receita.refracao?.OD?.cil || '');
  }, [receita.refracao?.OD?.cil]);

  useEffect(() => {
    setOlhoDireitoAdicao(receita.refracao?.OD?.adicao || '');
  }, [receita.refracao?.OD?.adicao]);

  useEffect(() => {
    setOlhoEsquerdoEsferico(receita.refracao?.OE?.esf || '');
  }, [receita.refracao?.OE?.esf]);

  useEffect(() => {
    setOlhoEsquerdoCilindrico(receita.refracao?.OE?.cil || '');
  }, [receita.refracao?.OE?.cil]);

  useEffect(() => {
    setOlhoEsquerdoAdicao(receita.refracao?.OE?.adicao || '');
  }, [receita.refracao?.OE?.adicao]);

  // eixo nao pode ser tratado igual pq o Editable Eixo tem useEffect que fica atualizando a cada load
  function handleEixoDireitoBlur(value) {
    var new_refracao : RefracaoDTO = receita.refracao;
    if(!new_refracao || !new_refracao.OD) {
      new_refracao = {...new_refracao, "OD": newRefracao()};
    }
    new_refracao.OD.eixo = value;
    alteraReceita({ ...receita, refracao: new_refracao });

    // se tem eixo e tinha erro, remove erro
    if((receita.refracao?.OD?.eixo && errorReceita.od_eixo) || !receita.refracao?.OD?.cil) {
      const tmp = errorReceita;
      delete tmp.od_eixo;
      setErrorReceita(tmp);
    }
    // se tem cil e nao tem eixo, adiciona erro
    if(receita.refracao?.OD?.cil && !receita.refracao?.OD?.eixo)
    {
      setErrorReceita({...errorReceita, "od_eixo": true}); 
    }
  }

  function newRefracao() {
    return {
      "esf": "",
      "cil": "",
      "eixo": "",
      "adicao": "",
    };
  }

  function handleEixoEsquerdoBlur(value) {
    var new_refracao : RefracaoDTO = receita.refracao;
    if(!new_refracao || !new_refracao.OE) {
      new_refracao = {...new_refracao, "OE": newRefracao()};
    }
    new_refracao.OE.eixo = value;
    alteraReceita({ ...receita, refracao: new_refracao });
    // se tem eixo e tinha erro, remove erro
    if((receita.refracao?.OE?.eixo && errorReceita.oe_eixo) || !receita.refracao?.OE?.cil) {
      const tmp = errorReceita;
      delete tmp.oe_eixo;
      setErrorReceita(tmp);
    }
    // se tem cil e nao tem eixo, adiciona erro
    if(receita.refracao?.OE?.cil && !receita.refracao?.OE?.eixo)
    {
      setErrorReceita({...errorReceita, "oe_eixo": true}); 
    }
  }
  
  function handleChange() {
    var new_refracao : RefracaoDTO = receita.refracao;
    if(!new_refracao) {
      new_refracao = {"OD": newRefracao(), "OE": newRefracao()};
    }
    const olhoDireito = {
      esf: olhoDireitoEsferico,
      cil: olhoDireitoCilindrico,
      adicao: olhoDireitoAdicao,
      eixo: (new_refracao.OD ? new_refracao.OD.eixo : ''),
    }; 
    const olhoEsquerdo = {
      esf: olhoEsquerdoEsferico,
      cil: olhoEsquerdoCilindrico,
      adicao: olhoEsquerdoAdicao,
      eixo: (new_refracao.OE ? new_refracao.OE.eixo : ''),
    }; 
    new_refracao = {...receita.refracao, OD: olhoDireito, OE: olhoEsquerdo };
    const new_receita = { ...receita, refracao: new_refracao };
    alteraReceita(new_receita);
    // se mudou algo, marcar para validar
    validarReceita(true, new_receita)
  }

  function formatAdicao(value, type) {
    var res = value.replace(/\D/g, "");
    if (res !== '') {
      res = parseInt(res)/100;
      value = res.toFixed(2);
      if(!value.startsWith("+") && res !== 0) {
        value = "+"+value;
      }
      value = value.replace(".", ",");
    }
    if(type === "OD")
      setOlhoDireitoAdicao(value);
    if(type === "OE")
      setOlhoEsquerdoAdicao(value);
  }

  function formatCilindro(value, type) {
    var res = value.replace(/\D/g, ""); // only numeric
    if (res !== '') {
      res = parseInt(res)/100;
      if(res === 0) {
        value = res.toFixed(2);
      } else {
        value = "-" + res.toFixed(2);
      }      
      value = value.replace(".", ",");
    }
    if(type === 'ODC')
      setOlhoDireitoCilindrico(value);
    if(type === 'OEC')
      setOlhoEsquerdoCilindrico(value);
  }

  function formatGrau(value, type) {
    var signal = '+';    
    if(value.includes("-")) {
      signal = '-';
    }
    var res = value.replace(/\D/g, ""); // only numeric
    if (res !== '') {
      res = parseInt(res)/100;
      if(res === 0) {
        value = res.toFixed(2);
      } else {
        value = signal + res.toFixed(2);
      }      
      value = value.replace(".", ",");
    }
    if(type === "ODE")
      setOlhoDireitoEsferico(value);
    if(type === 'ODC')
      setOlhoDireitoCilindrico(value);
    if(type === 'OEE')
      setOlhoEsquerdoEsferico(value);
    if(type === 'OEC')
      setOlhoEsquerdoCilindrico(value);
  }

  return (
  <>
  <Linha key="od">
    <Campo>
      <LabelOlho>OD</LabelOlho>
    </Campo>
    <MiniCampo>
      <Label className="ui_mini_labels">Esf</Label>
      <Editable 
        value={olhoDireitoEsferico}
        style={{ width: '50px'}}
        onChange={(e) => formatGrau(e.target.value, "ODE")}
        onKeyDown={onKeyDownAllowNumeric}
        onBlur={handleChange}
        maxLength="6"
        error={errorReceita.od_esferico ? "true" : "false"}
        />
    </MiniCampo>
    <MiniCampo>
      <Label className="ui_mini_labels">Cil</Label>
      <Editable 
        value={olhoDireitoCilindrico} 
        onChange={(e) => formatCilindro(e.target.value, "ODC")}
        style={{ width: '50px'}}
        onKeyDown={onKeyDownAllowNegative}
        onBlur={handleChange}
        maxLength="6"
        error={errorReceita.od_cilindrico ? "true" : "false"}
        inputMode="numeric"
        />
    </MiniCampo>
    <MiniCampo>
      <Label className="ui_mini_labels">Eixo</Label>
      <EditableEixo
        value={receita.refracao?.OD?.eixo}
        handleBlur={handleEixoDireitoBlur}
        error={errorReceita.od_eixo ? "true" : "false"}
        inputMode="numeric"
        />
    </MiniCampo>
    <MiniCampo>
      <Label className="ui_mini_labels">Adição</Label>
      <Editable 
        data-type="adicao"
        value={olhoDireitoAdicao} 
        onChange={(e) => formatAdicao(e.target.value, "OD")}
        style={{ width: '50px'}}
        onBlur={handleChange}
        onKeyDown={onKeyDownAllowOnlyPositiveNumbers}
        inputMode="numeric"
        />
    </MiniCampo>
  </Linha>
  <Linha key="oe">
    <Campo>
      <LabelOlho>OE</LabelOlho>
    </Campo>
    <Campo>
      <Label className="ui_mini_labels">Esf</Label>
      <Editable 
        value={olhoEsquerdoEsferico}
        style={{ width: '50px'}}
        onChange={(e) => formatGrau(e.target.value, "OEE")}
        onKeyDown={onKeyDownAllowNumeric}
        onBlur={handleChange}
        maxLength="6"
        error={errorReceita.oe_esferico ? "true" : "false"}
        />
    </Campo>
    <Campo>
      <Label className="ui_mini_labels">Cil</Label>
      <Editable 
        value={olhoEsquerdoCilindrico} 
        onChange={(e) => formatCilindro(e.target.value, "OEC")}
        style={{ width: '50px'}}
        onKeyDown={onKeyDownAllowNegative}
        onBlur={handleChange}
        maxLength="6"
        error={errorReceita.oe_cilindrico ? "true" : "false"}
        inputMode="numeric"
        />
    </Campo>
    <Campo>
      <Label className="ui_mini_labels">Eixo</Label>
      <EditableEixo
        value={receita.refracao?.OE?.eixo}
        handleBlur={handleEixoEsquerdoBlur}
        error={errorReceita.oe_eixo ? "true" : "false"}
        inputMode="numeric"
        />
    </Campo>
    <Campo>
      <Label className="ui_mini_labels">Adição</Label>
      <Editable 
        data-type="adicao"
        value={olhoEsquerdoAdicao} 
        onChange={(e) => formatAdicao(e.target.value, "OE")}
        style={{ width: '50px'}}
        onBlur={handleChange}
        onKeyDown={onKeyDownAllowOnlyPositiveNumbers}
        inputMode="numeric"
        />
    </Campo>
  </Linha>
  </>
  );
}
