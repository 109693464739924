import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  to {
      transform: rotate(360deg);
  }
`;

export const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  position: relative;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 0px;
`;

export const Cinza = styled.div`
  background: #EFEBE9;
`;

export const Branco = styled.div`
  padding-left: 25px;
  padding-right: 25px;
`;

export const Separador = styled.img`
`;

export const ConsultasContainer = styled.div`
  padding-bottom: 0px;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 20px;
  background: #EFEBE9;
`;

export const BotaoPararGravacao = styled.button`
  height: 45px;
  border-radius: 50px;
  background: #C71173;

  font-weight: 700;
  line-height: 17px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #fff;  

  flex: 1;
  border: none;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Botao = styled.button`
  margin-top: 20px;
  margin-bottom: 30px;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 44px;
  border-radius: 50px;
  gap: 10px;
  background: #811EF9;

  font-weight: 700;
  line-height: 17px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #fff;
  border: none;
`;

export const BotaoConfirmarTudo = styled.button`
  width: 100%;
  height: 44px;
  border-radius: 50px;
  background: #35155C;
  color: #ffffff;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Loader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${rotate} 1s infinite linear;
  padding: 10px;
`;
