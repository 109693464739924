import styled from 'styled-components'

export const Container = styled.div`
  cursor: pointer;
  flex: 1;
  display: flex;
  justify-content: start;
  align-items: center;
`;

export const Image = styled.img`
  margin: 8px 4px 8px 0;
`;

export const Label = styled.span`
  margin-left: 4px;
  &.ui_labels_bold {
    color: #35155C;
  }
`;
