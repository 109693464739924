import styled from 'styled-components'

export const Container = styled.div`
  height: 80px;
  //border: 1px solid red;
  display: flex;
  //justify-content: center;
  align-items: center;
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 20px;
`;

export const Logo = styled.img`
`;

export const ProfileContainer = styled.h2`
font-family: Inter;
font-size: 22px;
font-weight: 700;
line-height: 26.63px;
letter-spacing: -0.02em;
text-align: left;
`;
