import { 
  Container,
  Title,
  Content,
  Timestamps,
  Label,
  Coluna,
  Separador,
  BotaoReenviar
} from './styles'
import SeparadorImg from '../../assets/Separador.svg';
import BtnReenviar from '../../assets/Reenviar.svg';
import { useConsulta } from '@hooks/useConsulta';
import { env } from 'process';

export function AreaDoPaciente({ handleReenviar }) {  
  const {     
    enviado
  } = useConsulta();

  if(enviado) {
    return (
    <Container>
      <Separador src={SeparadorImg} /> 
      <Content>
        <Title>Área do Paciente</Title>
        <Timestamps>
          <Coluna>
            <Label className='ui_mini_labels'>Última atualização</Label>
            <div className='ui_labels'>12/12/2024 às 15:03</div>
          </Coluna>
          <Coluna>
            <Label className='ui_mini_labels'>Último envio por SMS e WhatsApp</Label>
            <div className='ui_labels'>12/12/2024 às 15:03</div>
          </Coluna>
        </Timestamps>
        <BotaoReenviar onClick={handleReenviar}>
          <img src={BtnReenviar} />
        </BotaoReenviar>
      </Content>
    </Container>
    );
  } else {
    return <div style={{marginBottom: '50px'}}></div>
  }
}