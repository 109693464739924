import { useState } from "react";
//import { useNavigate } from 'react-router-dom';
//import { logout, getUser } from "../../services/auth";

import DefaultFoto from '../../assets/foto_medico.png';
import BtnMenu from '../../assets/btn_menu.svg';
import MenuIconConta from '../../assets/menu_icon_conta.svg';
import MenuIconHelp from '../../assets/menu_icon_help.svg';
import MenuIconSignOut from '../../assets/menu_icon_signout.svg';
import MenuIconConfigs from '../../assets/menu_icon_configs.svg';
import { useAuth } from "../../hooks/useAuth";

import { 
  Container, 
  FotoContainer,
  Foto,
  ProfileContainer,
  Nome,
  Clinica,
  Menu,
  MenuPrincipal,
  MenuTitle,
  MenuItems,
  MenuItem,
  MenuIcon
} from './styles';

type HeaderDashboardProps = {
  handleClinicaChange: (clinica_id: string) => void;
  selected_clinica: string;
}

export function HeaderDashboard({ handleClinicaChange, selected_clinica }: HeaderDashboardProps) {
  const { user, signOut } = useAuth();
  const [ menuOpen, setMenuOpen ] = useState(false);

  function handleLogout() {
    signOut();
  }
  
  function getClinicasOptions() {
    const options = user.clinics.map((clinica) => {
      return <option key={clinica.id} value={clinica.id}>{clinica.title}</option>
    });
    return options;
  }

  return (
  <Container>
    <FotoContainer>
      <Foto src={user.profilePicture ? user.profilePicture : DefaultFoto } />
    </FotoContainer>
    <ProfileContainer>
      <Nome className="ui_labels">{user.displayName}</Nome>
      <Clinica onChange={(event) => handleClinicaChange(event.target.value)} className="ui_labels" value={selected_clinica}>
        {getClinicasOptions()}
      </Clinica>
    </ProfileContainer>
    <Menu onClick={() => setMenuOpen(!menuOpen)}>
      <img src={BtnMenu} alt="BtnMenu"/>
      {  menuOpen &&
      <MenuPrincipal>
        <MenuTitle href="https://hub.ver.ai/app" target="_blank">Verai Med</MenuTitle>
        <MenuItems>
          <MenuItem href="https://hub.ver.ai/app/conta" key="conta" target="_blank"><MenuIcon src={MenuIconConta} alt=""/>Conta</MenuItem>
          <MenuItem href="https://hub.ver.ai/app/config" key="configuracoes" target="_blank"><MenuIcon src={MenuIconConfigs} alt=""/> Configurações</MenuItem>
          <MenuItem href="https://hub.ver.ai/app/suporte" key="suporte" target="_blank"><MenuIcon src={MenuIconHelp} alt=""/>Suporte</MenuItem>
          <MenuItem key="sair" onClick={handleLogout}><MenuIcon src={MenuIconSignOut} alt=""/>Sair</MenuItem>
        </MenuItems>
      </MenuPrincipal>
      }
    </Menu>
  </Container>
  );
}