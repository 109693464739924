import React, { useState, useRef, useEffect } from "react";

import BtnAlterar from '../../assets/btn_alterar.svg';

import {
  Container,
  Input,
  DateInputMask,
  Imagem,
} from './styles';

export function Editable(props) {
  const [isMouseOver, setIsMouseOver] = useState(false);
  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if(inputRef.current) {
      // quando muda o valor por API, mostrar tamanho dinamico pq nao está editando
      if(document.activeElement !== inputRef.current) {
        inputRef.current.style.width = getWidthOfInput() + "px";
      }
    }
  }, [props.value]);


  function handleFocus (e) {
    if(inputRef.current) {
      inputRef.current.style.width = "95%";
    }
    setIsMouseOver(false);
  }

  const resizeInput = () => {
    inputRef.current.style.width = getWidthOfInput() + "px";
  };

  function handleMouseOver () {
    if (document.activeElement === inputRef.current) {
      return;
    }
    setIsMouseOver(true);
  };

  function handleMouseOut () {
    setIsMouseOver(false);
  }

  function handleClick() {
    inputRef.current.focus();
    //setIsEditing(true);
    setIsMouseOver(false);
  }

  function handleInputBlur(e) {
    if(inputRef.current) {
      resizeInput();
    }
    if(props.hasOwnProperty("onBlur")) {
      props.onBlur(e);
    }    
  }

  function setInputRef(element) {
    if(!inputRef.current){
      inputRef.current = element;
    }
  }

  function getWidthOfInput() {
    if(inputRef.current) {
      var tmp = document.createElement("span");
      tmp.classList.add("ui_labels");
      tmp.style.padding = "3px";
      tmp.innerHTML = inputRef.current.value.replace(/&/g,'&amp;').replace(/</g,'&lt;').replace(/>/g,'&gt;');
      var parent = inputRef.current.parentNode;
      if(!parent) {
        return 10;
      }
      parent.appendChild(tmp);
      var theWidth = Math.floor(tmp.getBoundingClientRect().width);
      parent.removeChild(tmp);
      return theWidth;
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      var form = event.target.form;
      var index = Array.prototype.indexOf.call(form, event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  }

  // ignorar o width se está sem foco e nao tem erro
  var ignoreMinWidth = (document.activeElement !== inputRef.current && !props.error);
  var minWidth = (props.minwidth && ignoreMinWidth ? 0 : props.minwidth );

  return (
    <Container onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
      {props.mask ?
        <DateInputMask 
          mask="99/99/9999"
          {...props}
          onBlur={handleInputBlur}
          inputRef={(el) => setInputRef(el)}
          onFocus={handleFocus}
          style={{minWidth: props.error === 'true' ? minWidth : 0}}
          className="ui_labels"
          //error={props.error === 'true' ? "true" : "false"}
          onKeyDown={handleKeyDown}
        />
      :
        <Input
          {...props }
          //error={props.error === 'true' ? "true" : "false"}
          onBlur={handleInputBlur}
          onFocus={handleFocus}
          ref={inputRef}
          style={{ minWidth: minWidth }}
          className="ui_labels"
          onKeyDown={handleKeyDown}
        />
      }
      {isMouseOver && <Imagem src={BtnAlterar} alt="editar" onClick={handleClick}/>}
    </Container>
  );
}