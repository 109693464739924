import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h3`
`;

export const Content = styled.div`
  padding: 0 20px 20px;
  background: #F5F5F5;
  gap: 8px;
  display: flex;
  flex-direction: column;
`;

export const Timestamps = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
`;

export const Label = styled.label`
  color: #989898;
`;

export const Coluna = styled.div`
`;

export const Separador = styled.img`
`;

export const BotaoReenviar = styled.button`
  width: 100%;
  background: transparent;
  border: 0;
  margin-top: 10px;

  display: flex;  
  align-items: center;
  justify-content: center;
  & img {
    width: 100%;
  }
`;
